import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";

import * as MarkdownStyles from "../components/markdown-block.styles";
import * as HeroStyles from "../components/hero.styles";
import * as Styles from "../styles/shared";

import { css } from "@emotion/react";

const S = {
  ...HeroStyles,
  ...MarkdownStyles,
  ...Styles,
};

const heroMast = css`
  height: 350px;
`;

const formStyle = css`
  max-width: 650px;
  margin: auto;
  margin-top: -160px;
  padding-top: 80px;
  position: relative;
  z-index: 10;
  background-color: white;
`;

const framePadding = css`
  padding-top: 30px;
  padding-bottom: 60px;
  min-height: 400px;
`;

const ThankYouPage = ({ title, intro }) => {
  return (
    <Layout>
      <div css={[S.bgBlack, S.relative, heroMast]} id="page-header">
        <StaticImage
          src={"../images/ted-at-work-form-header.jpg"}
          css={S.cover}
          alt=""
        />
      </div>
      <div css={[S.container, framePadding]}>
        <div css={[S.txtCenter, S.heroRoot, formStyle]} id="page-header">
          <S.Subtitle as="h1">{title}</S.Subtitle>
          {intro && <p>{intro}</p>}
        </div>
      </div>
    </Layout>
  );
};

ThankYouPage.defaultProps = {
  title: "Thanks for your interest in TED@Work!",
  intro: "We’ll get back to you as quickly as possible.",
};

export default ThankYouPage;
